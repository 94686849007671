import React, { memo } from 'react'

import './SellerMobileHeader.css'

export interface SellerMobileHeaderProps {
  logoSrc?: string
}

const SellerMobileHeader = ({ logoSrc }: SellerMobileHeaderProps) => (
  <header data-e2e="klarys-header" className="procsea-header">
    <img alt="ecosystem-logo" className="ecosystem-logo" src={logoSrc} />
  </header>
)

export default memo(SellerMobileHeader)
