import { UseInfiniteQueryOptions } from '@tanstack/react-query'

import { ApiPaginatedEntityResponse } from 'src/action-creators'
import { useUserSellerCompanyId } from 'src/contexts/userContext'
import { usePaginatedEntitiesQuery } from 'src/queries/entity'
import { GetSPIQueryObject, SellerProductInstanceWithStock } from 'src/types'

export const SPIS_WITH_STOCKS_ENDPOINT = 'seller_product_instances/list-with-stocks'
export const SPIS_WITH_STOCKS_QUERY_KEY = 'sellerProductInstances/listWithStocks'

export interface UsePaginatedSpisWithStocksQueryArgs<TData> {
  queryOptions?: UseInfiniteQueryOptions<
    ApiPaginatedEntityResponse<SellerProductInstanceWithStock>,
    Error,
    ApiPaginatedEntityResponse<TData>
  >
  queryParams?: GetSPIQueryObject
}

export const usePaginatedSpisWithStocksQuery = <TData = SellerProductInstanceWithStock>({
  queryParams = {},
  queryOptions = {},
}: UsePaginatedSpisWithStocksQueryArgs<TData> = {}) => {
  const sellerCompanyId = useUserSellerCompanyId()

  return usePaginatedEntitiesQuery<SellerProductInstanceWithStock, GetSPIQueryObject, TData>({
    endpoint: SPIS_WITH_STOCKS_ENDPOINT,
    queryKey: SPIS_WITH_STOCKS_QUERY_KEY,
    queryOptions: {
      ...queryOptions,
      enabled: !!sellerCompanyId && (queryOptions?.enabled ?? true),
    },
    queryParams: {
      ...queryParams,
      sellerCompanyId,
    },
  })
}
