import { UseQueryOptions } from '@tanstack/react-query'

import { useEntityQuery } from 'src/queries/entity'
import { GetSPIQueryObject, SellerProductInstanceWithStock } from 'src/types'

import { useMeQuery } from '../../me/me'

export const SPI_WITH_STOCKS_ENDPOINT = 'seller_product_instances/retrieve-with-stocks'
export const SPI_WITH_STOCKS_QUERY_KEY = 'sellerProductInstances/retrieveWithStocks'

interface UseSellerProductInstanceQueryArgs<TData> {
  id?: Id
  queryParams?: Partial<GetSPIQueryObject>
  queryOptions?: UseQueryOptions<SellerProductInstanceWithStock, Error, TData>
}

export const useSpiWithStocksQuery = <TData = SellerProductInstanceWithStock>({
  id,
  queryOptions = {},
  queryParams = {},
}: UseSellerProductInstanceQueryArgs<TData> = {}) => {
  const { data: seller } = useMeQuery()

  return useEntityQuery<SellerProductInstanceWithStock, Partial<GetSPIQueryObject>, TData>({
    endpoint: SPI_WITH_STOCKS_ENDPOINT,
    entityId: id,
    queryKey: SPI_WITH_STOCKS_QUERY_KEY,
    queryOptions: {
      ...queryOptions,
      enabled: !!queryParams.locationId && !!seller?.companyId && queryOptions?.enabled,
    },
    queryParams: { ...queryParams, sellerCompanyId: seller?.companyId },
  })
}
