import { Client } from '@twilio/conversations'
import { format, getUnixTime } from 'date-fns'
import { setDefaultOptions } from 'date-fns/setDefaultOptions'
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { AppWrapper, PageContent, registerLocale, setDefaultLocale } from '@procsea/design-system'

import { getSellerConversationContacts } from 'src/action-creators/conversationContacts'
import PageLoader from 'src/components/base/loader/PageLoader'
import NotFound from 'src/components/base/notFound/NotFound'
import ErrorBoundary from 'src/components/presentationals/shared/errors/ErrorBoundary'
import { APPLI_URL } from 'src/constants/constants'
import { useSeller, useUser, useUserSellerCompanyId } from 'src/contexts/userContext'
import { useCompanyVerticals, useOptionFlags, useSegment, useUserCounts } from 'src/hooks'
import useChat, { ConversationContext, createConversationClient } from 'src/hooks/useChat'
import useOrganisationPusherSubscriptions from 'src/hooks/useOrganisationPusherSubscription'
import { useMarketplacesQuery } from 'src/queries/shared'
import { enhancedConversationSelector } from 'src/selectors/conversationSelectors'
import {
  IdentifySellerArgs,
  LocaleParams,
  SellerUserWithCurrency,
  UserType,
  UserTypeParams,
} from 'src/types'
import { dateLocales } from 'src/utils'
import * as hotjarService from 'src/utils/tracking/hotjar/hotjar.service'

import SellerNavigation from './navigation/SellerNavigation'
import { usePusherSubscriptions } from './shared/hooks'

const AsyncSellerMarketRoutes = lazy(
  () => import('src/components/presentationals/seller-dashboard/market/SellerMarketRoutes')
)

const AsyncSellerStockManagement = lazy(
  () =>
    import('src/components/presentationals/seller-dashboard/stock-management/SellerStockManagement')
)

const AsyncSellerPurchaseMapping = lazy(
  () => import('src/components/presentationals/mapping/purchase-mapping/PurchaseMapping')
)

const AsyncManagement = lazy(() => import('src/components/presentationals/management/Management'))

const AsyncAnalytics = lazy(() => import('./analytics/Analytics'))

const AsyncChat = lazy(() => import('src/components/presentationals/chat/Chat'))

const AsyncInvoicing = lazy(
  () => import('src/components/presentationals/seller-dashboard/invoicing/Invoicing')
)
const AsyncPurchaseOrdersRoutes = lazy(
  () => import('src/components/presentationals/purchase-orders/PurchaseOrders.routes')
)

const AsyncOrdering = lazy(
  () => import('src/components/presentationals/seller-dashboard/ordering/Ordering')
)

const AsyncSellerCustomersRoutes = lazy(
  () => import('src/components/presentationals/seller-dashboard/customers/Routes')
)

const AsyncMyCompany = lazy(
  () => import('src/components/presentationals/seller-dashboard/my-company/MyCompany')
)

const AsyncCustomerCategories = lazy(
  () =>
    import('src/components/presentationals/seller-dashboard/customer-categories/CustomerCategories')
)

const AsyncCustomerCategory = lazy(
  () => import('src/components/presentationals/seller-dashboard/customer-category/CustomerCategory')
)

export interface SellerDashboardProps {
  seller: SellerUserWithCurrency
}

const SellerDashboard = () => {
  const seller = useSeller()
  const user = useUser()
  const {
    optionFlagCanUseCatalog,
    optionFlagCanUseManagement,
    optionFlagCanUseMapping,
    optionFlagCanUsePurchaseOrders,
  } = useOptionFlags()
  const { identify, isEnabled } = useSegment()

  const sellerCompanyId = useUserSellerCompanyId()
  const [conversationClient, setConversationClient] = useContext(ConversationContext)
  const [unreadConversationMessagesCount, setUnreadConversationMessagesCount] = useState(0)

  const dispatch = useDispatch()
  const { search } = useLocation()
  const { locale, userType } = useParams<LocaleParams & UserTypeParams>()
  const { data: ecosystems = [] } = useMarketplacesQuery()
  const verticals = useCompanyVerticals()
  const conversations = useSelector(enhancedConversationSelector(userType, ecosystems))

  const { todayOrdersToPrepareByVerticalCount, futureOrdersToPrepareByVerticalCount } =
    useUserCounts()

  usePusherSubscriptions()
  useOrganisationPusherSubscriptions()
  useChat()

  useEffect(() => {
    registerLocale(locale, dateLocales[locale])
    setDefaultLocale(locale)
    setDefaultOptions({ locale: dateLocales[locale] as any })
  }, [])

  useEffect(() => {
    if (sellerCompanyId) {
      getSellerConversationContacts(dispatch)({ sellerCompanyId })
    }
  }, [sellerCompanyId])

  useEffect(() => {
    if (user && seller && ecosystems.length > 0 && locale && isEnabled) {
      if (user.isImpersonate) {
        hotjarService.tagImpersonatedSeller()
      }
      const now = new Date()

      identify<IdentifySellerArgs>(`${seller.userId}`, {
        actorType: UserType.SELLER,
        allEcosystemIds: ecosystems.map(({ id }) => id).join(' - '),
        allEcosystemNames: ecosystems.map(({ name }) => name).join(' - '),
        companyCatalogDataIsValidated: seller.companyCatalogDataIsValidated,
        companyLegalDataIsValidated: seller.companyLegalDataIsValidated,
        companyName: seller.companyName,
        country: seller.country,
        createdAt: user.created,
        currencyId: seller.currencyId,
        ecosystemIds: ecosystems.map(({ id }) => id),
        ecosystemNames: ecosystems.map(({ name }) => name),
        email: user.email,
        firstName: user.firstName,
        futureOrdersToPrepare: futureOrdersToPrepareByVerticalCount ?? 0,
        impersonableUsers: user.impersonableUsers,
        impersonatorIsStaff: user.impersonatorIsStaff,
        isDemo: seller.companyIsDemo,
        isImpersonate: user.isImpersonate,
        labelIds: seller.labelIds,
        language: locale,
        lastName: user.lastName,
        lastSeen: getUnixTime(now),
        lastSeenDay: format(now, 'eeee'),
        lastSeenHour: format(now, 'H'),
        lastSeenMinute: format(now, 'm'),
        todayOrdersToPrepare: todayOrdersToPrepareByVerticalCount ?? 0,
      })
    }
  }, [JSON.stringify({ ecosystems, seller, user }), isEnabled, locale])

  useEffect(() => {
    if (seller?.canUseChat && !conversationClient) {
      createConversationClient().then((client: Client | null) => {
        setConversationClient(client)
      })
    }
  }, [seller?.canUseChat])

  useDeepCompareEffect(() => {
    setUnreadConversationMessagesCount(
      conversations.reduce((total, current) => total + current.unreadCount, 0)
    )
  }, [conversations])

  const getDefaultPageForRedirection = () => {
    if (optionFlagCanUseCatalog) {
      return `/${locale}${APPLI_URL.SELLER.MARKET.LIVE}`
    }
    if (optionFlagCanUsePurchaseOrders) {
      return `/${locale}${APPLI_URL.SELLER.PURCHASE_ORDERS}`
    }
    return `/${locale}${APPLI_URL.SELLER.ORDERS.BASE}`
  }

  return !!seller && verticals.length > 0 ? (
    <AppWrapper>
      <SellerNavigation unreadChatMessagesCount={unreadConversationMessagesCount} seller={seller} />
      <PageContent isLegacy={!user?.releaseToggleNewMenu}>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {optionFlagCanUseCatalog && (
              <Route
                path={[
                  `/:locale${APPLI_URL.SELLER.MARKET.BASE}`,
                  `/:locale${APPLI_URL.SELLER.PRODUCT.BASE}:action/:id?`,
                ]}
              >
                <AsyncSellerMarketRoutes />
              </Route>
            )}

            {seller.canUseStockManagementModule && (
              <Route path={`/:locale${APPLI_URL.SELLER.STOCK_MANAGEMENT}`}>
                <AsyncSellerStockManagement />
              </Route>
            )}

            {optionFlagCanUseMapping && (
              <Route
                path={[
                  `/:locale${APPLI_URL.SELLER.MAPPING.BASE}`,
                  `/:locale${APPLI_URL.SELLER.MAPPING.PURCHASES}`,
                ]}
              >
                <AsyncSellerPurchaseMapping />
              </Route>
            )}

            {optionFlagCanUsePurchaseOrders && (
              <Route path={`/:locale${APPLI_URL.SELLER.PURCHASE_ORDERS}`}>
                <ErrorBoundary>
                  <AsyncPurchaseOrdersRoutes />
                </ErrorBoundary>
              </Route>
            )}

            {optionFlagCanUseManagement && (
              <Route path={`/:locale${APPLI_URL.SELLER.MANAGEMENT.BASE}`}>
                <ErrorBoundary>
                  <AsyncManagement />
                </ErrorBoundary>
              </Route>
            )}

            <Route path={[`/:locale/:userType(seller)/orders`]}>
              <ErrorBoundary>
                <AsyncOrdering />
              </ErrorBoundary>
            </Route>

            <Route exact path="/:locale/:userType/analytics/:dashboardHash?">
              <ErrorBoundary>
                <AsyncAnalytics />
              </ErrorBoundary>
            </Route>

            {seller.canUseChat && (
              <Route path="/:locale/:userType/chat">
                <ErrorBoundary>
                  <AsyncChat />
                </ErrorBoundary>
              </Route>
            )}

            {seller.optionFlagDisplayInvoicesTab && (
              <Route path="/:locale/:userType/invoicing">
                <ErrorBoundary>
                  <AsyncInvoicing />
                </ErrorBoundary>
              </Route>
            )}

            <Route path="/:locale/:userType(seller)/my-company">
              <ErrorBoundary>
                <AsyncMyCompany />
              </ErrorBoundary>
            </Route>

            <Route path="/:locale/:userType(seller)/customer-categories">
              <ErrorBoundary>
                <AsyncCustomerCategories />
              </ErrorBoundary>
            </Route>

            <Route path="/:locale/:userType(seller)/customer-category/:customerCategoryId?">
              <ErrorBoundary>
                <AsyncCustomerCategory />
              </ErrorBoundary>
            </Route>

            <Route path={`/:locale${APPLI_URL.SELLER.CUSTOMERS.BASE}`}>
              <ErrorBoundary>
                <AsyncSellerCustomersRoutes />
              </ErrorBoundary>
            </Route>

            <Route exact path="/:locale/:userType(seller)/">
              <Redirect
                to={{
                  pathname: getDefaultPageForRedirection(),
                  search,
                }}
              />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </PageContent>
    </AppWrapper>
  ) : null
}

export default SellerDashboard
